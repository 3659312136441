<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      @excel="exportExcel"
      @search="search"
      @reset="reset"
      :fields="searchFields"
      show-export-excel
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :type="permissionType"
          :updatable="false"
          :view-modal="true"
          @view="viewStock"
        >
          <template #cell(state)="data">
            <span :class="`text-${data.item.state}`">
              {{ data.item.state }}
            </span>
          </template>
          <template #cell(requestSection)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-request-section',
                params: { id: data.item.requestSection.id },
              }"
              v-if="data.item.requestSection"
            >
              {{ data.item.requestSection.name }}
            </b-link>
          </template>
          <template #cell(requestPurpose)="data">
            <b-link
              class="link-underline"
              :to="{
                name: 'view-request-purpose',
                params: { id: data.item.requestPurpose.id },
              }"
              v-if="data.item.requestPurpose"
            >
              {{ data.item.requestPurpose.name }}
            </b-link>
          </template>
          <template #cell(requestedBy)="data">
            <b-link
              class="link-underline"
              :to="{
                name:
                  data.item.requestedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.requestedBy.id },
              }"
              v-if="data.item.requestedBy"
            >
              {{ data.item.requestedBy.name }}
            </b-link>
          </template>
          <template #cell(reviewedAt)="data">
            {{ data.item.reviewedAt | formatDate }}
          </template>
          <template #cell(reviewedBy)="data">
            <b-link
              class="link-underline"
              :to="{
                name:
                  data.item.reviewedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.reviewedBy.id },
              }"
              v-if="data.item.reviewedBy"
            >
              {{ data.item.reviewedBy.name }}
            </b-link>
          </template>
          <template #cell(issuedAt)="data">
            {{ data.item.issuedAt | formatDate }}
          </template>
          <template #cell(issuedBy)="data">
            <b-link
              class="link-underline"
              :to="{
                name:
                  data.item.issuedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.issuedBy.id },
              }"
              v-if="data.item.issuedBy"
            >
              {{ data.item.issuedBy.name }}
            </b-link>
          </template>
          <template #cell(receivedAt)="data">
            {{ data.item.receivedAt | formatDate }}
          </template>
          <template #cell(receivedBy)="data">
            <b-link
              class="link-underline"
              :to="{
                name:
                  data.item.receivedBy.typeId == 2
                    ? 'view-employee'
                    : 'view-administrator',
                params: { id: data.item.receivedBy.id },
              }"
              v-if="data.item.receivedBy"
            >
              {{ data.item.receivedBy.name }}
            </b-link>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <slot name="front-button" :item="data.item"></slot>
              <b-button
                @click="viewStock(data.item)"
                v-b-tooltip.hover
                :title="$t('general.stock')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="PackageIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
    <stock-request-line-modal
      ref="stockRequestLineModal"
    ></stock-request-line-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import StockRequestLineModal from "./stockRequestLine/Modal";
import moment from "moment";

const StockRequestRepository = Repository.get("stockRequest");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    StockRequestLineModal,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BLink,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      a: null,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || null,
        sectionId: Number(this.$route.query.sectionId) || null,
        purposeId: Number(this.$route.query.purposeId) || null,
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        period: this.$route.query.period || null,
        state: this.$route.query.state || null,
        type: this.$route.query.type || null,
      },
    };
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";

    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = searchText;
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.params.search = "";
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    exportExcel() {
      this.loading = true;
      let queries = [];
      if (this.params.type) {
        if (this.params.type == "open") {
          queries.push({
            searchby: "state",
            searchoperator: "in",
            search: ["requested", "approved", "prepared"],
          });
        } else {
          queries.push({
            searchby: "state",
            searchoperator: "in",
            search: ["rejected", "completed", "cancelled"],
          });
        }
      }

      StockRequestRepository.exportExcel({
        ...this.params,
        startDate: this.params.startDate
          ? `${this.params.startDate} 00:00:00`
          : null,
        endDate: this.params.endDate ? `${this.params.endDate} 23:59:59` : null,
        searchFields: this.searchFields,
        period: null,
        queries: queries,
      })
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }),
            url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Stock Request Report ${moment().format(
            "DD-MMM-YYYY"
          )}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      let queries = [];
      if (this.params.type) {
        if (this.params.type == "open") {
          queries.push({
            searchby: "state",
            searchoperator: "in",
            search: ["requested", "approved", "prepared"],
          });
        } else {
          queries.push({
            searchby: "state",
            searchoperator: "in",
            search: ["rejected", "completed", "cancelled"],
          });
        }
      }

      StockRequestRepository.index({
        ...this.params,
        startDate: this.params.startDate
          ? `${this.params.startDate} 00:00:00`
          : null,
        endDate: this.params.endDate ? `${this.params.endDate} 23:59:59` : null,
        searchFields: this.searchFields,
        period: null,
        queries: queries,
      })
        .then((response) => {
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewStock(item) {
      this.$refs.stockRequestLineModal.show({
        ...item,
      });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const permissionType = "stock-request";
    return {
      fields,
      searchFields,
      permissionType,
    };
  },
};
</script>