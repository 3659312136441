export default [
  {
    key: 'sectionId',
    label: 'field.requestSection',
    type: 'nAsynSingleSelection',
    repository: 'requestSection',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'purposeId',
    label: 'field.requestPurpose',
    type: 'nAsynSingleSelection',
    repository: 'requestPurpose',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'state',
    label: 'field.state',
    type: 'radio',
    options: [
      { text: 'general.requested', value: 'requested' },
      { text: 'general.approved', value: 'approved' },
      { text: 'general.rejected', value: 'rejected' },
      { text: 'general.prepared', value: 'prepared' },
      { text: 'general.completed', value: 'completed' },
      { text: 'general.cancelled', value: 'cancelled' },
    ],
    md: 12,
    lg: 6,
  },
  {
    key: 'startDate',
    label: 'field.fromDate',
    type: 'date',
    searchField: 'createdAt',
    operator: 'd>=',
  },
  {
    key: 'endDate',
    label: 'field.toDate',
    type: 'date',
    searchField: 'createdAt',
    operator: 'd<=',
  },
  {
    key: 'period',
    label: 'field.period',
    type: 'radio',
    options: [
      { text: 'field.today', value: 'today' },
      { text: 'field.yesterday', value: 'yesterday' },
      { text: 'field.thisWeek', value: 'thisWeek' },
      { text: 'field.lastWeek', value: 'lastWeek' },
      { text: 'field.thisMonth', value: 'thisMonth' },
      { text: 'field.lastMonth', value: 'lastMonth' },
    ],
    md: 12,
    lg: 6,
  },
  {
    key: 'type',
    label: 'field.type',
    type: 'radio',
    options: [
      { text: 'general.open', value: 'open' },
      { text: 'general.closed', value: 'closed' },
    ],
    skip: true,
  },
];
